import React, {useEffect, useState, useRef} from 'react';
import {
  Spin,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Tabs,
  Table,
  Tooltip,
  DatePicker,
  Modal,
  Upload,
  Dropdown,
  Menu,
  Select,
  Space,
  Switch,
  Divider,
} from 'antd';
import { THEME } from '../constants/theme';
import { ROUTES } from '../constants/routePaths';
import {useHistory, useLocation, useParams} from 'react-router';
import { globalApiService } from '../services/apiService';
import OpenNotification from '../utils/notification';
import {
  ArrowLeftOutlined,
  PlusOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import {AxiosError} from "axios";
import {useSelector} from "react-redux";
import {IProspect, IState} from "../models";
import moment from "moment";
import { Console } from 'console';

const EWayBill = () => {
  let docNumIndex = 0;

  //Initilization
  const history = useHistory();
  const [form] = Form.useForm();
  const location:any = useLocation();
  const params:any = useParams();
  const {id} = params
  const [loading, setLoading] = useState<boolean>(false);
  const authState = useSelector((state: IState) => state.authReducer);
  //Destructuring
  const { Title, Paragraph, Text } = Typography;
  const { Search } = Input;
  const { Option,OptGroup } = Select;

  //Handle Form
  const refForSubType:any = useRef(null)
  const refForDocType:any = useRef(null)
  const refForDocNumber:any = useRef(null)
  const refForTRansportName:any = useRef(null)
  const refForDistanceKm:any = useRef(null)



  const onEWayBilFormFinish = (values: any) => {
    submitEwayBill({...eWayBillState, 'eway_status': 'DRAFT'}, true);
  };

  function onDateChange(date: any, dateString: any) {
    if(dateString !== ''){
      setEWayBillState({'transport_date': dateString});
    }
  }

  function onDocDateChange(date: any, dateString: any) {
    if(dateString !== ''){
      setEWayBillState({'doc_date': dateString});
    }
  }

  const onEWayBillFormFailed = (errorInfo: any) => {
    let isBroken = false;
    errorInfo.errorFields.map((ele:any) => {
      if(isBroken){
        return;
      }
      let fieldName = ele.name[0]
      if(fieldName == 'sub_type'){
        refForSubType.current.focus();
        isBroken = true;
        return;
      }
      if(fieldName == 'doc_type'){
        refForDocType.current.focus();
        isBroken = true;
        return;
      }
      if(fieldName == 'doc_number'){
        refForDocNumber.current.focus();
        isBroken=true;
        return;
      }
      if(fieldName == 'transporter_name'){
        refForTRansportName.current.focus();
        isBroken=true;
        return;
      }
      if(fieldName == 'approx_distance'){
        refForDistanceKm.current.focus();
        isBroken=true;
        return;
      }
    })
    // console.log('Failed:', errorInfo);
  };

  // State Initilization

  const [transporters, setTransporters] = useState([]);
  const [invoices, setInvoices] = useState<any[]>([]);
  const [newInvName, setNewInvName] = useState('');
  const [customInvoice, setCustomInvoice] = useState(false);
  const [customerData,setCustomerData] = useState<any[]>([]);
  const [customerDuplicateData,setCustomerDuplicateData] = useState<any[]>([]);
  const [itemData,setItemData] = useState<any[]>([]);
  const [items, setItems] = useState([]);
  const [totalTaxable, setTotalTaxable] = useState(0);
  const [totalCgst, setTotalCgst] = useState(0);
  const [totalSgst, setTotalSgst] = useState(0);
  const [totalIgst, setTotalIgst] = useState(0);
  const [totalCess, setTotalCess] = useState(0);
  const [eWayBillState, setEWayBillState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      transaction_type: 'OUTWARD',
      sub_type: '',
      doc_type: '',
      doc_number: '',
      doc_date: '',

      // From
      from_name: '',
      from_gstin: '',
      from_address: '',
      from_place: '',
      from_pincode: '',
      from_state: '',

      // To
      to_name: '',
      to_gstin: '',
      to_address: '',
      to_place: '',
      to_pincode: '',
      to_state: '',

      // Transporter Details
      mode_of_transportation: 'ROAD',
      transporter_name: '',
      transport_id: '',
      transporter_id: '',
      transporter_doc_number: '',
      transport_date: '',
      approx_distance: '',
      vehicle_number: '',
    }
  );


  const onEWayBillChange = (evt: any) => {
    const { name } = evt.target;
    const newValue = evt.target.value;
    setEWayBillState({[name]: newValue});
  };
  const handleCustomerSelectChange = (value: string) => {
    customerData
      .filter((temp) => {
        if(temp.company_name === value){
          const customer_loc: any = temp.api_response?.pradr?.addr || {};
          const customer = {
            'to_name': temp.company_name,
            'to_gstin': temp.gstin,
            'to_address': temp.address,
            'to_place': `${customer_loc.dst} ${customer_loc.loc}`,
            'to_pincode': customer_loc.pncd,
            'to_state': customer_loc.stcd,
          };
          setEWayBillState({
            ...eWayBillState, ...customer});
        }
        return temp.id === value;
      });
  };

  const onNameChange = (evt: any) => {
    setNewInvName(evt.target.value);
  };

  const addInvoice = (evt: any) => {
    evt.preventDefault();
    // eslint-disable-next-line no-plusplus
    setInvoices([...invoices, {'invoice_number' :newInvName || `New item ${docNumIndex++}`}]);
    setNewInvName('');
  };


  const handleEWayBillSelectChange = (value: string, name: string) => {
    setEWayBillState({[name]: value });
  };

  // Table Config
  const onTableInputChange = (e: any, index: number) => {
    const { name } = e.target;
    const { value } = e.target;
    const duplicateColData = [...colData];
    duplicateColData[index][name] = value;
    setColData([...duplicateColData]);
  };
  const onQtyInputChange = (e: any, index: number) => {
    let name: any = e.target.name;
    let value: any = e.target.value;
      if(parseFloat(value) === 0){
        value=1
      }
      const duplicateColData = [...colData];
      duplicateColData[index]['qty'] = value;
      setColData([...duplicateColData]);
      // }
  };

  const handleUnitsSelectChange = (value: string, index: any) => {
    const duplicateColData = [...colData];
    duplicateColData[index].units = value;
    setColData([...duplicateColData]);
  };

  const handleAddRow = () => {
    setColData([
      ...colData,
      {
        index: colData.length,
        itemService: '',
        description: '',
        hsnCode: '',
        qty: 1,
        units: '',
        value: 0,
        cgst: 0,
        sgst: 0,
        igst: 0,
        cess: 0,
      },
    ]);
  };
  const onTransporterChange= (value: string) => {
    const transporter:any = transporters
      .filter((temp: any) => temp.name === value);
    setEWayBillState({'transporter_name':value, 'transporter_id': transporter[0].id, 'transport_id': transporter[0].transporter_id, 'vehicle_number':transporter[0].vehicle_no});
  };
  const onInvoiceChange = (value: string) => {
    const invoice:any = invoices.filter((temp:any) => temp.invoice_number === value)
    if(invoice.length > 0 && Object.keys(invoice[0]).indexOf('customer') > -1) {
      const customer_loc: any = invoice[0].customer.api_response?.pradr?.addr || {};
      const customer = {
        'to_name': invoice[0].customer.company_name,
        'to_gstin': invoice[0].customer.gstin,
        'to_address': invoice[0].customer.address,
        'to_place': `${customer_loc.dst} ${customer_loc.loc}`,
        'to_pincode': customer_loc.pncd,
        'to_state': customer_loc.stcd,
      };
      setEWayBillState({
        ...eWayBillState, ...customer,
        'doc_number': value,
        'doc_date': moment(new Date(invoice[0].invoice_date)).format('DD/MM/YYYY')
      });
      let totTax = 0;
      let totCgst = 0;
      let totSgst = 0;
      let totIgst = 0;
      let totCess = 0;
      const itemsList: any = items.filter((temp: any) => temp.invoice === invoice[0].id).map((el: any, idx: any) =>
        ({
          index: idx,
          itemService: el.item.name,
          description: el.item.description,
          hsnCode: el.item.hsn_code,
          qty: el.quantity,
          units: el.item.unit,
          value: parseFloat(el.quantity) * parseFloat((el.price_per_unit).toFixed(2)) - ((parseFloat(el.quantity) * parseFloat((el.price_per_unit).toFixed(2))) * parseFloat((el.discount_percent).toFixed(2)) / 100),
          cgst: el.cgst_percent || 0,
          sgst: el.sgst_percent || 0,
          igst: el.igst_percent || 0,
          cess: el.item.cess || 0,
        }));

      itemsList.map((ele: any) => {
        totTax += parseFloat(ele.value);
        totCgst += (parseFloat(ele.value) * parseFloat((ele.cgst).toFixed(2)) / 100);
        totSgst += (parseFloat(ele.value) * parseFloat((ele.sgst).toFixed(2)) / 100);
        totIgst += (parseFloat(ele.value) * parseFloat((ele.igst).toFixed(2)) / 100);
        totCess += (parseFloat(ele.value) * parseFloat((ele.cess).toFixed(2)) / 100);
      });
      totTax = totTax - parseFloat((invoice[0].discount || 0).toFixed(2)) - parseFloat((invoice[0].tds || 0).toFixed(2)) + parseFloat((invoice[0].shipping_charges || 0).toFixed(2)) - parseFloat((invoice[0].received || 0).toFixed(2));
      setTotalCess(totCess);
      setTotalCgst(totCgst);
      setTotalIgst(totIgst);
      setTotalSgst(totSgst);
      setTotalTaxable(totTax);
      setColData([...itemsList]);
    } else {
      setEWayBillState({
        ...eWayBillState,
        'doc_number': value,
        'doc_date': moment(new Date()).format('DD/MM/YYYY')
      });
      setCustomInvoice(true);
    }
  };



  const fetchFromInfo = (gstin:any) => {
    setLoading(true);
    globalApiService.fetchEwayBill(gstin).then((res) => {
      if(res.data.success){
        const sellerInformation = res.data?.data?.seller_information;
        setEWayBillState({...sellerInformation });
        setTransporters(res.data?.data?.transporters);
      } else {
        OpenNotification(res?.data?.message || 'Error Occured', 'error');
      }
      setLoading(false);
    }).catch((err: AxiosError) => {
      const errorBody = err?.response?.data;
      setLoading(false);
    });
  };
  const fetchIRNInvoices = () => {
    setLoading(true);
    globalApiService.fetchIrnInvoices().then((res) => {
      if(res.data.success){
        setInvoices(res.data?.data?.invoices);
        setItems(res.data?.data?.items);
        if (res.data.data.invoices.length > 0){
          prepopulateFunctionForEinvoiceID(res.data.data.invoices,res.data?.data?.items)
        }
      } else {
        OpenNotification(res?.data?.message || 'Error Occured', 'error');
      }
      setLoading(false);
    }).catch((err: AxiosError) => {
      const errorBody = err?.response?.data;
      setLoading(false);
    });
  };
  const prepopulateFunctionForEinvoiceID = (data:any,itemsData:any) => {
    if(location !== undefined && location.state !== undefined && location.state.e_invoice_id){
      const {e_invoice_id} = location?.state
      data.map((ele:any) => {
        if(ele.id == e_invoice_id){
          setInvoices([ele])
          setEWayBillState({
            'doc_type': 'INVOICE',
            'doc_number': ele.invoice_number,
            'doc_date': moment(new Date(ele.invoice_date)).format('DD/MM/YYYY'),
            'to_name': ele.customer.company_name,
            'to_gstin': ele.customer.gstin,
            'to_address': ele.customer.address,
            'to_place': `${ele.customer.api_response?.pradr?.addr.dst  } ${ele.customer.api_response?.pradr?.addr.loc}`,
            'to_pincode': ele.customer.api_response?.pradr?.addr.pncd,
            'to_state': ele.customer.api_response?.pradr?.addr.stcd,
          })
          let totTax = 0;
          let totCgst = 0;
          let totSgst = 0;
          let totIgst = 0;
          let totCess = 0;
          const itemsList:any = itemsData.filter((temp:any) => temp.invoice === e_invoice_id).map((el: any, idx:any) =>
          ({
            index: idx,
            itemService: el.item.name,
            description: el.item.description,
            hsnCode: el.item.hsn_code,
            qty: el.quantity,
            units: el.item.unit,
            value: parseFloat(el.quantity) * parseFloat((el.price_per_unit).toFixed(2)) - ((parseFloat(el.quantity) * parseFloat((el.price_per_unit).toFixed(2))) * parseFloat((el.discount_percent).toFixed(2)) / 100),
            cgst: el.cgst_percent || 0,
            sgst: el.sgst_percent || 0,
            igst: el.igst_percent || 0,
            cess: el.item.cess || 0,
          }));

        itemsList.map((ele: any) => {
          totTax += parseFloat(ele.value);
          totCgst += (parseFloat(ele.value) * parseFloat((ele.cgst).toFixed(2)) / 100);
          totSgst += (parseFloat(ele.value) * parseFloat((ele.sgst).toFixed(2)) / 100);
          totIgst += (parseFloat(ele.value) * parseFloat((ele.igst).toFixed(2)) / 100);
          totCess += (parseFloat(ele.value) * parseFloat((ele.cess).toFixed(2)) / 100);
        });
        totTax = totTax - parseFloat((ele.discount || 0).toFixed(2)) - parseFloat((ele.tds || 0).toFixed(2)) + parseFloat((ele.shipping_charges || 0).toFixed(2)) - parseFloat((ele.received || 0).toFixed(2));
        setTotalCess(totCess);
        setTotalCgst(totCgst);
        setTotalIgst(totIgst);
        setTotalSgst(totSgst);
        setTotalTaxable(totTax);
        // console.log(itemsList)
        setColData([...itemsList]);
        }
      })
      }
  };

  React.useEffect(() => {
    globalApiService
      .getAllCustomers()
      .then((res) => {
        if(res.data.success){
          let tempArray:any[] = []
          res.data.data.results.map((ele:any) => {
            if(ele.has_gstin){
              tempArray.push({...ele})
            }
            setCustomerData([...tempArray])
          })
          setCustomerDuplicateData(res.data.data.results)
        }
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        console.log(errorBody);
      });
      globalApiService
      .fetchItems()
      .then((res) => {
        if(res.data.success){
          res.data.data.results.map((ele:any,idx:number) => {
            setItemData((prevState) => [...prevState, {...ele,'index':idx,'tax_percent': 0,'is_tax_included': 'included','price_per_unit': ele.price, 'discount_percent':0, 'quantity': 1}])
          })

        }

      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        console.log(errorBody);
      });
  },[])
  React.useEffect(()=> {
    fetchIRNInvoices();
    if(!params.id){
      fetchFromInfo(authState.gstin);
    }
    if(location !== undefined && location.state !== undefined && location.state.data && invoices.length) {
      const {data} = location?.state;
      setEWayBillState({...data});
      // if(Object.keys(data).indexOf('from_gstin') == -1){
        onInvoiceChange(data.doc_number);
        fetchFromInfo(authState.gstin);
      // }
      if(Object.keys(data).indexOf('total_value') > -1){
        setTotalTaxable(data.total_value);
      }
    }else {
      if(params.id) {
        globalApiService
          .getSingleEwaybill(params.id)
          .then((res: any) => {
            if (res.data.success) {
              const docDate = moment(new Date(res.data.data?.ewb_data.doc_date)).format('DD/MM/YYYY')
              const transportDate = moment(new Date(res.data.data?.ewb_data.transport_date)).format('DD/MM/YYYY');
              const invData = res.data.data?.inv_data;
              const itemsData = res.data.data.items;
              setTransporters(res.data?.data?.transporters);
              let tempEwbData:any = {}
              let ewbFields = [
                'id',
                'eway_bill_date',
                'doc_number',
                'doc_type',
                'eway_status',
                'from_address',
                'from_gstin',
                'from_name',
                'from_pincode',
                'from_place',
                'from_state',
                'irn_number',
                'mode_of_transportation',
                'sub_type',
                'to_address',
                'to_gstin',
                'to_name',
                'to_pincode',
                'to_place',
                'to_state',
                'transaction_type',
                'irn_number',
                'transporter_name',
                'transporter_doc_number',
                'approx_distance',
                'vehicle_number',
                'transport_id'

              ]
              ewbFields.map((ele:any) => {
                if(res.data.data.ewb_data[ele]){
                  tempEwbData[ele] = res.data.data.ewb_data[ele]
                }
              })
              if(res.data.data.ewb_data['doc_date']){
                tempEwbData['doc_date'] = docDate
              }
              if(res.data.data.ewb_data['transport_date']){
                tempEwbData['transport_date'] = transportDate
              }
              if(res.data.data.ewb_data.transporter !== null){
                tempEwbData['transporter'] = {...res.data.data.ewb_data.transporter}
              }
              setEWayBillState({...tempEwbData
                // 'transporter_id': res.data.data.ewb_data.transporter.id, 'doc_date': docDate, 'transport_date': transportDate
              });
              let totTax = 0;
              let totCgst = 0;
              let totSgst = 0;
              let totIgst = 0;
              let totCess = 0;
              if(itemsData.length > 0) {
                const itemsList: any = itemsData.filter((temp: any) => temp.invoice === invData.id).map((el: any, idx: any) =>
                  ({
                    index: idx,
                    itemService: el.item.name,
                    description: el.item.description,
                    hsnCode: el.item.hsn_code,
                    qty: el.quantity,
                    units: el.item.unit,
                    value: parseFloat(el.quantity) * parseFloat((el.price_per_unit).toFixed(2)) - ((parseFloat(el.quantity) * parseFloat((el.price_per_unit).toFixed(2))) * parseFloat((el.discount_percent).toFixed(2)) / 100),
                    cgst: el.cgst_percent || 0,
                    sgst: el.sgst_percent || 0,
                    igst: el.igst_percent || 0,
                    cess: el.item.cess || 0,
                  }));

                itemsList.map((ele: any) => {
                  totTax += parseFloat(ele.value);
                  totCgst += (parseFloat(ele.value) * parseFloat((ele.cgst).toFixed(2)) / 100);
                  totSgst += (parseFloat(ele.value) * parseFloat((ele.sgst).toFixed(2)) / 100);
                  totIgst += (parseFloat(ele.value) * parseFloat((ele.igst).toFixed(2)) / 100);
                  totCess += (parseFloat(ele.value) * parseFloat((ele.cess).toFixed(2)) / 100);
                });
                totTax = totTax - parseFloat((invData.discount || 0).toFixed(2)) - parseFloat((invData.tds || 0).toFixed(2)) + parseFloat((invData.shipping_charges || 0).toFixed(2)) - parseFloat((invData.received || 0).toFixed(2));
                setTotalCess(totCess);
                setTotalCgst(totCgst);
                setTotalIgst(totIgst);
                setTotalSgst(totSgst);
                setTotalTaxable(totTax);
                setColData([...itemsList]);
              } else {
                setCustomInvoice(true);
                setColData([...res.data.data.ewb_data.items]);
              }
            }
          })
          .catch((err: AxiosError) => {
            const errorBody = err?.response?.data;
          });
      }
    }
  }, [id]);

  const [eWayBillId,setEwayBillId] = useState(0);
  const submitEwayBill = (data:any, routePreview:boolean) => {
    if(eWayBillState.doc_number == "" || eWayBillState.doc_type == ""){
      OpenNotification('Document Type or Document Number is missing','error')
    }
    if(colData.length == 0 || colData[0].itemService==''){
      OpenNotification('Please select an Item','error')
    }
    else{

      // if(routePreview) {
      //   history.push(`${ROUTES.EWAYBILL_PREVIEW}`, {'formData': {...data, 'ewb_id':'', 'total_value': totalTaxable}});
      // }
      //eslint-disable-next-line no-param-reassign
      // data.doc_date = moment(eWayBillState.doc_date).format('DD/MM/YYYY');
      if(data.transport_date == ''){
        data.transport_date = moment(new Date()).format('DD/MM/YYYY')
      }
      setLoading(true);
      const temp_items = colData.filter((temp: any) => temp.itemService !== '');
      if (temp_items.length > 0) {
        data.items = temp_items;
      }
      if(params.id){
        let updateData = {
          mode_of_transportation: data.mode_of_transportation,
          transporter_name: data.transporter_name,
          transport_id: data.transport_id,
          transporter_id: data.transporter_id,
          transporter_doc_number: data.transporter_doc_number,
          transport_date: data.transport_date,
          approx_distance: data.approx_distance,
          vehicle_number: data.vehicle_number,
          to_address: data.to_address,
          to_name: data.to_name,
          to_gstin: data.to_gstin,
          sub_type: data.sub_type,
          to_place: data.to_place,
          to_pincode: data.to_pincode,
          to_state: data.to_state,
          items: data.items,
        };

        globalApiService.updateEwayBill(params.id, updateData).then((res: any) => {
          if (res.data.success) {
            OpenNotification(res?.data?.data?.message, 'success');
            if (routePreview) {
              history.push(`${ROUTES.EWAYBILL_PREVIEW}`, {
                'formData': {
                  ...data,
                  'eway_bill_date': moment(new Date(data.eway_bill_date)).format('DD/MM/YYYY'),
                  'hsn_code': colData[0].hsnCode,
                  'ewb_id': params.id,
                  'total_value': totalTaxable
                }
              });
            }
          } else {
            OpenNotification(res?.data?.message || 'Error Occured', 'error');
          }
          setLoading(false);
        }).catch((err: AxiosError) => {
          const errorBody = err?.response?.data;
          setLoading(false);
        });
      }
      else {
        if(eWayBillId){
          data.ewb_id = eWayBillId
        }
        globalApiService.submitEwayBill(data).then((res: any) => {
          if (res.data.success) {
            setEwayBillId(res.data?.data?.ewb_id)
            OpenNotification(res?.data?.data?.message, 'success');
            if (routePreview) {
              history.push(`${ROUTES.EWAYBILL_PREVIEW}`, {
                'formData': {
                  ...data,
                  'hsn_code': colData[0].hsnCode,
                  'ewb_id': res.data?.data?.ewb_id,
                  'total_value': totalTaxable
                }
              });
            }
          } else {
            setEwayBillId(0)
            OpenNotification(res?.data?.message || 'Error Occured', 'error');
          }
          setLoading(false);
        }).catch((err: AxiosError) => {
          const errorBody = err?.response?.data;
          setLoading(false);
        });
      }
    }

  };
  
  const [colData, setColData] = React.useState([
    {
      index: 0,
      itemService: '',
      description: '',
      hsnCode: '',
      qty: '',
      units: '',
      value: '',
      cgst: '',
      sgst: '',
      igst: '',
      cess: '',
    },
  ]) as any[];
  const [addedItemsName,setAddedItemsName] = useState<any[]>([])
  const handleRemoveRow = (index: number,name:string) => {
    const duplicateColData = [...colData];
    const noIndexChange = duplicateColData.slice(0,index)
    const indexChange = duplicateColData.slice(index+1)
    const manipulatedIndex:any[] = []
    indexChange.map((ele) => {
        ele['index'] = ele.index - 1
      manipulatedIndex.push({...ele})
    })
    setColData([...noIndexChange,...manipulatedIndex]);

  };
  useEffect(() => {
    let tempArray:any = []
    colData.map((ele:any) => {
      tempArray.push(ele.itemService)
    })
    setAddedItemsName([...tempArray])
  },[JSON.stringify(colData)])

  const onItemChange = (value: string, index: number) => {
    itemData.filter((temp: any) => {
      return temp.name === value
    }).map((el: any, idx: any) => {
      const tempColData = [...colData];
      tempColData[index] = {
        index,
        discount_percent: el.discount_percent,
        price_per_unit: el.price_per_unit,
        itemService: el.name,
        description: el.description,
        hsnCode: el.hsn_code,
        qty: el.quantity,
        units: el.unit,
        value: parseFloat(el.quantity) * parseFloat((el.price_per_unit).toFixed(2)) - ((parseFloat(el.quantity) * parseFloat((el.price_per_unit).toFixed(2))) * parseFloat((el.discount_percent).toFixed(2)) / 100),
        cgst: el.cgst || 0,
        sgst: el.sgst || 0,
        igst: el.igst || 0,
        cess: el.cess || 0,
        type:el.type,
      };

      return setColData([...tempColData]);
    });
  };

  const col = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text: any, record: any) => {
        return record.index + 1;
      },
    },
    {
      title: 'Item/service',
      dataIndex: 'itemService',
      key: 'itemService',
      render: (text: any, record: any) => {
        return (
          <div>
          {!customInvoice &&
          <Tooltip title={record.itemService}>
          <Input
            readOnly
            name="itemService"
            value={record.itemService}
            onChange={(e) => onTableInputChange(e, record.index)}
            // placeholder="Enter customer GSTIN"
          />
          </Tooltip>
          }
            {
            customInvoice &&
            <Tooltip title={record.itemService}> 
            <Select
              className="search-item"
              showSearch
              style={{width: '11rem'}}
              defaultValue={'Search Item'}
              value={record.itemService}
              placeholder="abc"
              optionFilterProp="children"
              // onChange={(e) => onTableInputChange(e, record.index)}
              onChange={(value: any) => onItemChange(value, record.index)}
              filterOption={(input: any, option: any) =>
                typeof option.children == 'string'  && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {itemData.map((item, idx) => {
              if(!(addedItemsName.includes(item.name))){
              return <Option key={idx} value={item.name}>
                {item.name}
              </Option>
              }
            })}
            </Select>
            </Tooltip>
            }
          </div>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text: any, record: any) => {
        return (
          <Tooltip title={record.description}>
          <Input
            // disabled
            name="description"
            value={record.description}
            onChange={(e) => onTableInputChange(e, record.index)}
            // placeholder="Enter customer GSTIN"
          />
          </Tooltip>
        );
      },
    },
    {
      title: 'HSN code',
      dataIndex: 'hsnCode',
      key: 'hsnCode',
      render: (text: any, record: any) => {
        return (
          <Input
            // disabled
            name="hsnCode"
            value={record.hsnCode}
            onChange={(e) => onTableInputChange(e, record.index)}
            // placeholder="Enter customer GSTIN"
          />
        );
      },
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty',
      render: (text: any, record: any) => {
        return (
          <Tooltip title={record.qty}>
          <Input
            readOnly={!customInvoice}
            type="number"
            name="qty"
            min='1'
            value={record.qty}
            onChange={(e) => onQtyInputChange(e, record.index)}
            // placeholder="Enter customer GSTIN"
          />
          </Tooltip>
        );
      },
    },
    {
      title: 'Units',
      dataIndex: 'units',
      key: 'units',
      render: (text: any, record: any) => {
        return (
          <Tooltip title={record.units}>
          <Input
          // disabled
          name="units"
          value={record.units}
          onChange={(e) => onTableInputChange(e, record.index)}
          // placeholder="Enter customer GSTIN"
        />
        </Tooltip>
        );
      },
    },
    {
      title: 'Value/Taxable value (Rs.)',
      dataIndex: 'value',
      key: 'value',
      render: (text: any, record: any) => {
        if(customInvoice) {
          colData[record.index].value = parseFloat(record.qty) * parseFloat((record.price_per_unit || 0).toFixed(2)) - ((parseFloat(record.qty) * parseFloat((record.price_per_unit || 0).toFixed(2))) * parseFloat((record.discount_percent || 0).toFixed(2)) / 100)
          colData[record.index].value = parseFloat((colData[record.index].value || 0).toFixed(2));
        }
        return (
          <div>
          {!customInvoice && 
          <Tooltip title={!isNaN(colData[record.index].value)? colData[record.index].value: ''}>
          <Input
            disabled
            name="value"
            value={
              !isNaN(colData[record.index].value)
                ? colData[record.index].value
                : ''
            }
            onChange={(e) => onTableInputChange(e, record.index)}
            // placeholder="Enter customer GSTIN"
          />
          </Tooltip>
          }
          {customInvoice && 
          <Tooltip title={record?.value}>
          <Input
            // disabled
            name="value"
            value={record?.value
            }
            onChange={(e) => onTableInputChange(e, record.index)}
            // placeholder="Enter customer GSTIN"
          />
          </Tooltip>
          }
          </div>
        );
      },
    },
    {
      title: 'Tax rate',
      dataIndex: '',
      key: '',
      render: (text: any, record: any) => {
        return (
          <div className="tax-rate">
            <div>
              <p className="tax-rate__text">CGST</p>
              <Input
                // disabled
                className="tax-rate__input"
                name="cgst"
                value={record.cgst}
                onChange={(e) => onTableInputChange(e, record.index)}
                // placeholder="Enter customer GSTIN"
              />
            </div>
            <div>
              <p className="tax-rate__text">SGST</p>
              <Input
                // disabled
                className="tax-rate__input"
                name="sgst"
                value={record.sgst}
                onChange={(e) => onTableInputChange(e, record.index)}
                // placeholder="Enter customer GSTIN"
              />
            </div>
            <div>
              <p className="tax-rate__text">IGST</p>
              <Input
                // disabled
                className="tax-rate__input"
                name="igst"
                value={record.igst}
                onChange={(e) => onTableInputChange(e, record.index)}
                // placeholder="Enter customer GSTIN"
              />
            </div>
            <div>
              <p className="tax-rate__text">CESS</p>
              <Input
                // disabled
                className="tax-rate__input"
                name="cess"
                value={record.cess}
                onChange={(e) => onTableInputChange(e, record.index)}
                // placeholder="Enter customer GSTIN"
              />
            </div>
          </div>
        );
      },
    },
      {
      title: '',
      dataIndex: '',
      key: '',
      width: '2%',
      render: (text: any, record: any) => {
        return (
          <div className="remove-row">
            {customInvoice && record.index !== 0 && (
              <CloseCircleOutlined
                className="remove-row__icon"
                onClick={() => handleRemoveRow(record.index,record.itemService)}
              ></CloseCircleOutlined>
            )}
          </div>
        );
      },
    },
  ];

  React.useEffect(() => {
    if(customInvoice && colData){
      console.log('colData',colData)
      let totTax = 0;
      let totCgst = 0;
      let totSgst = 0;
      let totIgst = 0;
      let totCess = 0;

      colData.map((ele: any) => {
        totTax += parseFloat(ele.value);
        totCgst += (parseFloat(ele.value) * parseFloat((ele.cgst ? ele.cgst : "0")) / 100);
        totSgst += (parseFloat(ele.value) * parseFloat((ele.sgst ? ele.sgst : "0")) / 100);
        totIgst += (parseFloat(ele.value) * parseFloat((ele.igst ? ele.igst : "0")) / 100);
        totCess += (parseFloat(ele.value) * parseFloat((ele.cess ? ele.cess : "0")) / 100);
      });
      setTotalCess(totCess);
      setTotalCgst(totCgst);
      setTotalIgst(totIgst);
      setTotalSgst(totSgst);
      setTotalTaxable(totTax);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colData]);

  function onTableChange(pagination: any, filters: any, sorter: any) {
    console.log('params', pagination, filters, sorter);
  }
  form.setFieldsValue({
    ...eWayBillState,
  });
  // @ts-ignore
    // @ts-ignore
    return (
    <main>
      <Row>
        <Title level={3}>E invoicing</Title>
      </Row>
      <div className="ewaybill-header-container">
        <ArrowLeftOutlined
          className="ewaybill-header-container__icon"
          onClick={() => history.push(ROUTES.E_INVOICE)}
        />
        <h4 className="ewaybill-header-container__text">E way bill</h4>
      </div>
      <section className="transaction-details">
        <h3 className="transaction-details__title">Transaction details</h3>
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onEWayBilFormFinish}
          onFinishFailed={onEWayBillFormFailed}
          autoComplete="off"
          className="ewaybill-form"
          form={form}
        >
          <Form.Item label="Transaction Type" name="transaction_type">
            <Radio.Group
              disabled={Object.keys(params).indexOf('id') > -1}
              value={eWayBillState.transaction_type}
              name="transaction_type"
              onChange={onEWayBillChange}
            >
              <div className="radio-container">
                <Radio value="OUTWARD">
                  <span className="radiotext">Outward</span>
                </Radio>
                <Radio value="INWARD">
                  <span className="radiotext">Inward</span>
                </Radio>
              </div>
            </Radio.Group>
          </Form.Item>
          <div className="ewaybill-threecolgrid">
            <Form.Item label="Sub Type" name="sub_type" rules={[{ required:true, message: 'Sub Type is a mandatory field'}]}>
              <Select
                ref={refForSubType}
                className='select-border-radius'
                size="large"
                // disabled={Object.keys(params).indexOf('id') > -1}
                defaultValue="Select"
                onChange={(value) =>
                  handleEWayBillSelectChange(value, 'sub_type')
                }
              >
                <Option value="SUPPLY">Supply</Option>
                <Option value="EXPORT">Export</Option>
                <Option value="JOB_WORK">Job Work</Option>
                <Option value="SKD/CKD">SKD/CKD</Option>
                <Option value="RECEPIENT_NOT_KNOWN">Recipient not known</Option>
                <Option value="FOR_OWN_USE">For own use</Option>
                <Option value="EXHIBITION_OF_FAIRS">Exhibition of fairs</Option>
                <Option value="LINE_SALES">Line sales</Option>
                <Option value="OTHERS">Others</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Document Type"
              name="doc_type"
              className="doc-type"
              rules={[{ required:true, message: 'Type of doc type is a mandatory field'}]}
            >
              <Select
                ref={refForDocType}
                className='select-border-radius'
                size="large"
                disabled={Object.keys(params).indexOf('id') > -1}
                defaultValue="INVOICE"
                onChange={(value) =>
                  handleEWayBillSelectChange(value, 'doc_type')
                }
              >
                <Option value="INVOICE">Invoice</Option>
                <Option value="BILL">Bill</Option>
                <Option value="CHALLAN">Challan</Option>
                <Option value="CREDIT_NOTE">Credit note</Option>
                <Option value="BILL_OF_ENTRY">Bill of entry</Option>
                {/* <Option value="For own use">For own use</Option> */}
                <Option value="OTHERS">Other</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Document Number"
              name="doc_number"
              rules={[{ required:true, message: 'Type of doc number is a mandatory field'}]}
              >
              <Select
                ref={refForDocNumber}
                className='select-border-radius'
                size="large"
                disabled={Object.keys(params).indexOf('id') > -1}
                showSearch
                value={eWayBillState?.doc_number}
                onChange={(value: any) => onInvoiceChange(value)}
                placeholder="Select Document Number"
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                dropdownRender={menu => (
                  <>
                    {menu}
                    <Divider style={{margin: '8px 0'}} />
                    {eWayBillState.doc_type == 'INVOICE' && <>
                    <Space style={{padding: '0 8px 4px', textAlign:'center'}}>
                     <Input placeholder="Please Enter Invoice Number" value={newInvName} onChange={onNameChange} />
                      <a onClick={addInvoice} style={{whiteSpace: 'nowrap'}}>
                        <PlusOutlined /> Add New
                      </a>
                    </Space>
                    </>
                     }
                  </>
                )}
              >
                {eWayBillState.doc_type == 'INVOICE' && invoices.map((inv:any, idx) => (
                  <Option key={idx} value={inv.invoice_number}>
                    {inv.invoice_number}
                  </Option>
                ))}
              </Select>
              {/*<Select*/}
              {/*  disabled={Object.keys(params).indexOf('id') > -1}*/}
              {/*  showSearch*/}
              {/*  placeholder="Select Document Number"*/}
              {/*  value={eWayBillState?.doc_number}*/}
              {/*  optionFilterProp="children"*/}
              {/*  onChange={(value: any) => onInvoiceChange(value)}*/}
              {/*  filterOption={(input: any, option: any) =>*/}
              {/*    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
              {/*  }*/}
              {/*>*/}
              {/*  {eWayBillState.doc_type == 'INVOICE' && invoices.map((inv:any, idx) => (*/}
              {/*    <Option key={idx} value={inv.invoice_number}>*/}
              {/*      {inv.invoice_number}*/}
              {/*    </Option>*/}
              {/*  ))}*/}
              {/*</Select>*/}
            </Form.Item>
            <label style={{color: 'black'}}>
              Document Date<br/>
              <DatePicker
                inputReadOnly
                // disabled={Object.keys(params).indexOf('id') > -1}
                style={{height: '32px', marginTop:'5px'}}
                format={'DD/MM/YYYY'}
                value={eWayBillState?.doc_date !== '' ? moment(eWayBillState.doc_date, 'DD/MM/YYYY')
                  : moment()}
                className="transporter-doc-date-container__datepicker"
                onChange={(date, dateString) =>
                  onDocDateChange(date, dateString)}
            />
            </label>
          </div>
          <h3 className="transaction-details__title transaction-details__title--mt">
            From
          </h3>
          <div className="ewaybill-twocolgrid">
            <Form.Item label="Name" name="from_name">
              <Input
                className='input-class'
                disabled
                name="from_name"
                value={eWayBillState.from_name}
                onChange={onEWayBillChange}
                placeholder="Enter Name"
              />
            </Form.Item>
            <Form.Item label="GSTIN" name="from_gstin">
              <Input
                className='input-class'
                disabled
                name="from_gstin"
                value={eWayBillState.from_gstin}
                onChange={onEWayBillChange}
                placeholder="Enter GSTIN"
              />
            </Form.Item>
          </div>
          <div className="ewaybill-fourcolgrid">
            <Form.Item label="Address" name="from_address">
              <Input.TextArea
                className='input-class'
                disabled
                rows={2}
                name="from_address"
                value={eWayBillState.from_address}
                onChange={onEWayBillChange}
                placeholder="Enter Address"
              />
            </Form.Item>
            <Form.Item label="Place" name="from_place">
              <Input
                className='input-class'
                disabled
                name="from_place"
                value={eWayBillState.from_place}
                onChange={onEWayBillChange}
                placeholder="Enter Place"
              />
            </Form.Item>
            <Form.Item label="Pin code" name="from_pincode">
              <Input
                className='input-class'
                disabled
                name="from_pincode"
                value={eWayBillState.from_pincode}
                onChange={onEWayBillChange}
                placeholder="Enter Pin code"
              />
            </Form.Item>
            <Form.Item label="State" name="from_state">
              <Input
                className='input-class'
                disabled
                name="from_state"
                value={eWayBillState.from_state}
                onChange={onEWayBillChange}
                placeholder="Enter State"
              />
            </Form.Item>
          </div>
          <h3 className="transaction-details__title transaction-details__title--mt">
            To
          </h3>
          <div className="ewaybill-twocolgrid">
            {!customInvoice && <Form.Item label="Name" name="to_name">
              <Input
                className='input-class'
                readOnly
                name="to_name"
                value={eWayBillState.to_name}
                onChange={onEWayBillChange}
                placeholder="Enter Name"
              />
            </Form.Item>}
            {customInvoice && <Form.Item
              label="Name"
              name="to_name"
              className="customer-name"
              rules={[{ required:true, message: 'Customer name is a mandatory field'},]}
            >
              <Select
                className='select-border-radius'
                size="large"
                showSearch
                style={{ width: '100%' }}
                defaultValue="Select Existing Customer"
                placeholder="abc"
                optionFilterProp="children"
                onChange={(value: any) =>
                  handleCustomerSelectChange(value)
                }
                filterOption={(input: any, option: any) =>
                  typeof option.children == 'string'  && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                
                {customerData.map((item, idx) =>{
                  if(item.gstin_status.toLowerCase()=='active'.toLowerCase())
                  return(
                  <Option key={idx} value={item.company_name}>
                    {item.company_name}
                  </Option>
                )})}
                <OptGroup label="Inactive GSTIN Status">
                {customerData.map((item, idx) => {
                  if(item.gstin_status.toLowerCase() != 'active'.toLowerCase())
                  return (
                  <Option key={idx} value={item.id} disabled>
                    {item.company_name}
                  </Option>
                  )
                })}
                </OptGroup>
              </Select>
            </Form.Item>}
            <Form.Item label="GSTIN" name="to_gstin">
              <Input
                className='input-class'
                disabled
                name="to_gstin"
                value={eWayBillState.to_gstin}
                onChange={onEWayBillChange}
                placeholder="Enter GSTIN"
              />
            </Form.Item>
          </div>
          <div className="ewaybill-fourcolgrid">
            <Form.Item label="Address" name="to_address">
              <Input.TextArea
                className='input-class'
                rows={2}
                name="to_address"
                value={eWayBillState.to_address}
                onChange={onEWayBillChange}
                placeholder="Enter Address"
              />
            </Form.Item>
            <Form.Item label="Place" name="to_place">
              <Input
                className='input-class'
                name="to_place"
                value={eWayBillState.to_place}
                onChange={onEWayBillChange}
                placeholder="Enter Place"
              />
            </Form.Item>
            <Form.Item label="Pin code" name="to_pincode">
              <Input
                className='input-class'
                name="to_pincode"
                value={eWayBillState.to_pincode}
                onChange={onEWayBillChange}
                placeholder="Enter Pin code"
              />
            </Form.Item>
            <Form.Item label="State" name="to_state">
              <Input
                className='input-class'
                name="to_state"
                value={eWayBillState.to_state}
                onChange={onEWayBillChange}
                placeholder="Enter State"
              />
            </Form.Item>
          </div>
          <Table
            columns={col}
            dataSource={colData}
            onChange={onTableChange}
            pagination={false}
            rowClassName="table-row-bg"
          />
          {customInvoice && <div className="table-add-row">
            <p className="table-add-row__text" onClick={handleAddRow}>
              {' '}
              <PlusOutlined />
              Add row
            </p>
          </div>}
          <div className="summary-container">
            <div className="eway-total-twocolgrid">
              <p className="eway-total-label">Total amt./Taxable amt.</p>
              <Input  disabled className="eway-total-input input-class" value={(totalTaxable || 0).toFixed(2)} />
              <p className="eway-total-label">CGST</p>
              <Input disabled className="eway-total-input input-class" value={(totalCgst || 0).toFixed(2)} />
              <p className="eway-total-label">SGST</p>
              <Input disabled className="eway-total-input input-class" value={(totalSgst || 0).toFixed(2)} />
              <p className="eway-total-label">IGST </p>
              <Input disabled className="eway-total-input input-class" value={(totalIgst || 0).toFixed(2)} />
              <p className="eway-total-label">CESS</p>
              <Input disabled className="eway-total-input input-class" value={(totalCess || 0).toFixed(2)} />
            </div>
          </div>
          <h3 className="transaction-details__title transaction-details__title--mt">
            Transporter Details
          </h3>
          <Form.Item label="Mode" name="mode_of_transportation"
          rules={[{ required:true, message: 'Mode is a mandatory field'}]}
          >
            <Radio.Group
              value={eWayBillState.mode_of_transportation}
              name="mode_of_transportation"
              onChange={onEWayBillChange}
            >
              <div className="radio-container">
                <Radio value="ROAD">
                  <span className="radiotext">Road</span>
                </Radio>
                <Radio value="RAIL">
                  <span className="radiotext">Rail</span>
                </Radio>
                <Radio value="AIR">
                  <span className="radiotext">Air</span>
                </Radio>
                <Radio value="SHIP">
                  <span className="radiotext">Ship</span>
                </Radio>
              </div>
            </Radio.Group>
          </Form.Item>
          <div className="transporter-details-threecolgrid">
            <div className="transporter-details-threecolgrid__firstele">
              <Form.Item label="Transporter Name" name="transporter_name"
              rules={[{ required:true, message: 'Transporter is a mandatory field'}]}
              >
              <Select
                ref={refForTRansportName}
                className='select-border-radius'
                size="large"
                showSearch
                placeholder="Select Transporter"
                value={eWayBillState?.transporter_name}
                optionFilterProp="children"
                onChange={(value: any) => onTransporterChange(value)}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {transporters.map((trans:any, idx) => (
                  <Option key={idx} value={trans.name}>
                    {trans.name}
                  </Option>
                ))}
              </Select>
              </Form.Item>
              <Form.Item
                label="Transport id"
                name="transport_id"
              >
                <Input
                  className='input-class'
                  disabled
                  name="transport_id"
                  value={eWayBillState.transport_id}
                  onChange={onEWayBillChange}
                  placeholder="Enter transporter id"
                />
              </Form.Item>
              <div className="transporter-doc-date-container">
                <p className="transporter-doc-date-container__text">
                  Transporter document number & date
                </p>
                <Form.Item label="" name="transporter_doc_number"
                rules={[{ required:true, message: 'Transporter document number is a mandatory field'}]}
                >
                  <Input
                    className='input-class'
                    placeholder="Enter Document Number"
                    name="transporter_doc_number"
                    value={eWayBillState.transporter_doc_number}
                    onChange={onEWayBillChange}
                    // placeholder="Enter transporter id"
                  />
                </Form.Item>
                {/*<Form.Item label="" name="transport_date">*/}
                  <DatePicker
                    style={{height:'32px'}}
                    format={'DD/MM/YYYY'}
                    value={eWayBillState?.transport_date !== '' ? moment(eWayBillState.transport_date, 'DD/MM/YYYY')
                      : moment()}
                    className="transporter-doc-date-container__datepicker"
                    onChange={(date, dateString) =>
                      onDateChange(date, dateString)}
                  />
                {/*</Form.Item>*/}
              </div>
            </div>
            <div className="transporter-details-threecolgrid__secondele">
              <p className="transporter-details-threecolgrid__secondele__text">
                OR
              </p>
            </div>
            <div className="transporter-details-threecolgrid__thirdele">
              <Form.Item label="Approximate distance in KM" name="approx_distance"
              rules={[{ required:true, message: 'Distance is a mandatory field'}]}
              >
                <Input
                  ref={refForDistanceKm}
                  className='input-class'
                  name="approx_distance"
                  value={eWayBillState.approx_distance}
                  onChange={onEWayBillChange}
                  // placeholder="Enter transporter id"
                />
              </Form.Item>
              <div>
                <Form.Item label="Vehicle no." name="vehicle_number"
                rules={[{ required:true, message: 'Vehicle Number is a mandatory field'}]}
                >
                  <Input
                    className='input-class'
                    name="vehicle_number"
                    value={eWayBillState.vehicle_number}
                    onChange={onEWayBillChange}
                    // placeholder="Enter transporter id"
                  />
                  <p className="">(Format AB12AB1234)</p>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="previewbtnbox">
            <Form.Item>
              <p
                className="previewbtnbox__btn previewbtnbox__btn--paragraph"
                style={{
                  color: THEME.PRIMARY_COLOR,
                }}
                onClick={() =>submitEwayBill({...eWayBillState, 'eway_status': 'DRAFT'}, false)}
              >
                Save as Draft
              </p>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="previewbtnbox__btn"
                style={{
                  backgroundColor: THEME.PRIMARY_COLOR,
                }}
                // onClick={() => history.push(ROUTES.EWAYBILL_PREVIEW)}
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </section>
    </main>
  );
};
export default EWayBill;
