import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd/es/grid';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { RiEdit2Fill, RiEditLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Paragraph from 'antd/es/typography/Paragraph';
import { Button, Spin, Typography, Radio, Divider, Space, Input, Modal, Card, Tabs, Upload, message, Checkbox, Form } from 'antd';
import { InboxOutlined, CloseOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import OtpInput from 'react-otp-input';
import { IState } from '../models';
import { ROUTES } from '../constants/routePaths';
import { THEME } from '../constants/theme';


import InputColor from '../components/InputColor';
import 'rc-color-picker/assets/index.css';
import 'antd/dist/antd.css';
import '../styles/base.css';

import { QRCode, withLogoInvoice, withoutLogoInvoice } from '../utils/constants';
import { globalApiService } from '../services/apiService';
import ImportedColorPicker from '../components/colorPicker';
import OpenNotification from '../utils/notification';
// eslint-disable-next-line import/order
import { AxiosError } from 'axios';
import { UploadOutlined, EyeOutlined } from '@ant-design/icons';

export default function CompanyProfilePage() {
  // Destructing
  const [form] = Form.useForm();
  const authState = useSelector((state: IState) => state.authReducer);
  // console.log(authState);

  const OTP_RESEND_DISABLE_TIMEOUT_MS = 10000;
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpError, setOtpError] = useState<boolean>(false);
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => setIsResendDisabled(false), OTP_RESEND_DISABLE_TIMEOUT_MS);
  }, []);


  function resendOtpAndDisableBtn() {
    // Request for OTP again
    sendOtp(phoneNumber)
    setTimeout(() => setIsResendDisabled(true), OTP_RESEND_DISABLE_TIMEOUT_MS);
  }

  // Submit OTP
  const submitOtp = () => {
    // Function to Submit OTP
    if(otpp.length === 4){
      const data = {'phoneNumber': phoneNumber, otp:otpp, new_phone:value.phone};
      verifyOtp(data);
      setotpp('');
    }
  };

  const [otpp, setotpp] = useState('');
  const [value, setValue] = React.useReducer(
    (state:any, newState:any) => ({...state, ...newState}),
    {
      registration_date: '',
      gstin: '',
      state: '',
      trade_name: '',
      pan: '',
      address: '',
      business_type: 'Both',
      registration_type: '',
      logoUrl: '',
      email: '',
      phone: '',
      whatsapp_opt: true,
      gst_username: '',
      gst_password: '',
      eway_username:'',
      eway_password:'',
      bankDetails: [{
        bank_name: '',
        branch: '',
        ifsc: '',
        account_no: '',
        branch_swift: '',
      }],
    }
  );

  const [invSettings, setInvoiceSettings] = React.useReducer(
    (state:any, newState:any) => ({...state, ...newState}),
    {
      b2b_theme: '',
      b2b_preference: '',
      b2b_prefix: '',
      b2c_theme: '',
      b2c_preference: '',
      b2c_prefix: ''
    }
  );

  const { TabPane } = Tabs;
  const { Dragger } = Upload;

  const [isWhatsappModalVisible, setIsWhatsappModalVisible] = useState(false);
  const [isVerifyMobileModalVisible, setIsVerifyMobileModalVisible] = useState(false);
  const [isNoteVisible,setIsNoteVisible] = useState(false)
  const [profileEmail,setProfileEmail] = useState('')

  const [prefixForm] = Form.useForm();



  const closeVerifyMobileModal = () => {
    setIsVerifyMobileModalVisible(false);
  };

  // For Whatsapp Modal
  const handleWhatsappOk = () => {
    setIsWhatsappModalVisible(false);
  };

  const handleWhatsappCancel = () => {
    setIsWhatsappModalVisible(false);
  };

  const validatePhoneNumber = () => {
    sendOtp(phoneNumber);
    setIsVerifyMobileModalVisible(true);
  };

  const handleWhatsAppConsent = (consent: boolean) => {
    if (consent) {
      setValue({'whatsapp_opt':consent});
    }
    setIsWhatsappModalVisible(false);
    value.whatsapp_opt = consent;
    // Function to Save the details
    postProfileData({...value, ...invSettings});
  };


  // Handle Form
  const onAddDetailsFinish = (values: any) => {
    let tempBankDetails:any[] = []
    let tempBankAccountCheck:any = []
    
    value.bankDetails.map((ele:any,idx:any) => {
      
      if(!(tempBankAccountCheck.indexOf(ele) != -1)){
        tempBankAccountCheck.push(ele)
      }
    })
    for(let i=0; i<tempBankAccountCheck.length; i++){
      const tempCountForAccountSwiftCheck = value.bankDetails.filter((ele:any) => {
        return ele.account_no == tempBankAccountCheck[i].account_no && ele.branch_swift == tempBankAccountCheck[i].branch_swift
      })
      if(tempCountForAccountSwiftCheck.length >= 2){
        OpenNotification('Account number and Branch swift cannot be repeated','error')
        return
      }
    }
    for(let i=0; i<tempBankAccountCheck.length; i++){
      const tempCountForAccountIfscCheck = value.bankDetails.filter((ele:any) => {
        return ele.account_no == tempBankAccountCheck[i].account_no && ele.ifsc == tempBankAccountCheck[i].ifsc
      })
      if(tempCountForAccountIfscCheck.length >= 2){
        OpenNotification('Account number and Ifsc cannot be repeated','error')
        return
      }
    }
    value.bankDetails.map((ele:any,idx:any) => {
      if(ele.bank_name && ele.branch && ele.ifsc && ele.branch_swift && ele.account_no){
        tempBankDetails.push({...ele})
      }
    })
    // Check if phone number gets changed
    if (value.phone !== phoneNumber) {
      validatePhoneNumber();
    } else if (!value.whatsapp_opt) {
      setIsWhatsappModalVisible(true);
    }else {
      // Function to Save the details
      postProfileData({...value,'bankDetails':[...tempBankDetails], ...invSettings});
    }
  };

  const onAddDetailsFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const onDetailsChange = (e: any) => {
    const { name } = e.target;
    const value = e.target.value;
    
    if(e.target.name !== 'whatsapp_opt'){
      setValue({[name]:value});
    }
    if(e.target.name === 'whatsapp_opt'){
      const { checked } = e.target;
      setValue({[name]:checked});
    }
    if(e.target.name === 'email'){
      if(profileEmail != e.target.value){
        setIsNoteVisible(true)
      }else{
        setIsNoteVisible(false)
      }
    }
  };
  const onInvSettingsChange = (e:any) => {
    const { name } = e.target;
    const { value } = e.target;
    if (value.length <= 4){
      setInvoiceSettings({[name]:value});
      return;
    }
    OpenNotification('Prefix if more than 4 characters only first 4 characters will be saved','error')
  };

  const onBankDetailsChange = (
    e: { target: { name: number | string; value: number | string } },
    index: number,
    valueBankDetails:any
  ) => {
    const { name, value } = e.target;
    const duplicateBankDetails = [...valueBankDetails];
    duplicateBankDetails[index][name] = value;
    setValue({'bankDetails': duplicateBankDetails});
    // setBankDetails([...duplicateBankDetails]);
    // console.log(bankDetails);
  };

  // Before Upload
  function beforeUpload(file: any, fileList: any) {
    // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    // if (!isJpgOrPng) {
    //   console.error('You can only upload JPG/PNG file!');
    // }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //   console.error('Image must smaller than 2MB!');
    // }
    return true;
  }
  const addAnotherBank = () => {
    // setValue({'bankDetails'})
    const temp = {
      bank_name: '',
      branch: '',
      ifsc: '',
      account_no: '',
      branch_swift: '',
    };
    const duplicateBankDetails = [...value.bankDetails, temp];
    setValue({'bankDetails': duplicateBankDetails});
  };

  const clearBank =(id:number,index: number) => {
    if(id){
      globalApiService.validateBankAccountDelete(id).then((res) => {
        if(res.data.success){
         if(res.data.data.isInvoiceExist){
          OpenNotification(res.data.data.message, 'error');
         }else {
          const temp:any = {
            bank_name: '',
            branch: '',
            ifsc: '',
            account_no: '',
            branch_swift: '',
          };
          let duplicateBankDetails:any[] = []
          if(value.bankDetails.length == 1){
            duplicateBankDetails = [...value.bankDetails,temp];
          }else {
            duplicateBankDetails = [...value.bankDetails];
          }
          duplicateBankDetails.splice(index, 1);
          setValue({'bankDetails': duplicateBankDetails});
        }
        }
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
      });
    }else{
    const temp:any = {
      bank_name: '',
      branch: '',
      ifsc: '',
      account_no: '',
      branch_swift: '',
    };
    let duplicateBankDetails:any[] = []
    if(value.bankDetails.length == 1){
      duplicateBankDetails = [...value.bankDetails,temp];
    }else {
      duplicateBankDetails = [...value.bankDetails];
    }
    duplicateBankDetails.splice(index, 1);
    setValue({'bankDetails': duplicateBankDetails});
    }
  };

  const removeBankRow = (id:number,index: number) => {
    if(id){
    globalApiService.validateBankAccountDelete(id).then((res) => {
      if(res.data.success){
       if(res.data.data.isInvoiceExist){
        OpenNotification(res.data.data.message, 'error');
       }else {
        // OpenNotification('Error Occurred', 'error');
        const duplicateBankDetails = [...value.bankDetails];
        duplicateBankDetails.splice(index, 1);
        setValue({'bankDetails': duplicateBankDetails});
      }
      }
    })
    .catch((err: AxiosError) => {
      const errorBody = err?.response?.data;
    });
  }else {
    const duplicateBankDetails = [...value.bankDetails];
    duplicateBankDetails.splice(index, 1);
    setValue({'bankDetails': duplicateBankDetails});
  }
  }

  // Invoice Setting Config

  const onColorChange = (color:any, index:number) => {
    if(index===0){
      setInvoiceSettings({'b2b_theme':color});
    }
    if(index===1){
      setInvoiceSettings({'b2c_theme':color});
    }
  };



  // const [activePreview,setActivePreview] = useState({
  // 0:true
  // })
  const [activePreview, setActivePreview] = useState(
    [true, true]
  );
  const handlePreviewClick =  (idx:number, value:boolean) => {
    if(idx === 0){
      if(value){
        setInvoiceSettings({'b2b_preference': 'With Logo'});
      } else{
        setInvoiceSettings({'b2b_preference': 'Without Logo'});
      }
    }
    if(idx === 1){
      if(value){
        setInvoiceSettings({'b2c_preference': 'With Logo'});
      } else{
        setInvoiceSettings({'b2c_preference': 'Without Logo'});
      }
    }
    const dupicateActivePreview = activePreview;
    dupicateActivePreview[idx] = value;
    setActivePreview([...dupicateActivePreview]);
  };

  // Send OTP
  const sendOtp = (phone:any) => {
    setLoading(true);
    globalApiService.generateProfileOtp(phone).then((res) => {
      if(res.data.success){
        OpenNotification('OTP Sent Successfully', 'success');
      } else {
        OpenNotification('Error Occured', 'error');
      }
      setLoading(false);
    }).catch((err: AxiosError) => {
      setLoading(false);
    });
  };

  // Verify OTP
  const verifyOtp = (data:any) => {
    setLoading(true);
    globalApiService.verifyProfileOtp(data).then((res) => {
      if(res.data.success){
        setIsVerifyMobileModalVisible(false);
        fetchProfileDetails(authState.gstin);
      } else {
        OpenNotification('Error Occurred', 'error');
      }
      setLoading(false);
    }).catch((err: AxiosError) => {
      setLoading(false);
    });
  };

  // Save Profile Settings
  const postProfileData = (data:any) => {
    setLoading(true);
    globalApiService.postProfileData(data).then((res) => {
      if(res.data.success){
        fetchProfileDetails(authState.gstin);
        OpenNotification('Profile Updated Successfully', 'success');

      } else {
        OpenNotification('Error Occured', 'error');
      }
      setLoading(false);
    }).catch((err: AxiosError) => {
      setLoading(false);
    });
  };

  // Get Profile Details
  const fetchProfileDetails = (gstin:any) => {
    setLoading(true);
    globalApiService.fetchProfileDetails(gstin).then((res) => {
      if(res.data.success){
        if (res.data.data.general_info.bankDetails.length === 0) {
          res.data.data.general_info.bankDetails = [{
            bank_name: '',
            branch: '',
            ifsc: '',
            account_no: '',
            branch_swift: '',
          }];
        }
        const responseData = res.data.data.general_info;
        const invoiceSettings = res.data.data.inv_settings;
        setPhoneNumber(responseData.phone);
        setProfileEmail(responseData.email);
        setValue({...value, ...responseData});
        prefixForm.setFieldsValue({
          ...invSettings,
          ...invoiceSettings,
        });
        setInvoiceSettings({...invSettings, ...invoiceSettings});
        setActivePreview([invoiceSettings.b2b_preference === 'With Logo', invoiceSettings.b2c_preference === 'With Logo']);
      } else {
        OpenNotification('Error Occurred', 'error');
      }
      setLoading(false);
    }).catch((err: AxiosError) => {
      const errorBody = err?.response?.data;
      setLoading(false);
    });
  };
  form.setFieldsValue({...value, ...value.bankDetails});

  const [logoUrl,setLogoUrl] = useState('')
  const [logoFileName,setLogoFileName] = useState('')

  const [isLogoViewModalOpen,setIsLogoViewModalOpen] = useState(false)

  const handleLogoModalClose = () => {
    setIsLogoViewModalOpen(false)
  }

  const getLogoUrl = () => {
    globalApiService.getLogoUrl().then((response)=>{
      if(response.data.success){
        setLogoUrl(response.data.data.logo_url)
      }
    }).catch((err: AxiosError) => {
      const errorBody = err?.response?.data;
      console.log(errorBody)
    });
  }
  
  React.useEffect(()=>{
    fetchProfileDetails(authState.gstin);
    getLogoUrl()
  }, [authState.gstin]);

  const props:any = {
    name: 'file',
    multiple: false,
    headers: {
      authorization: `Token ${authState.token}`,
    },
    showUploadList: false,
    accept: '.jpeg,.jpg,.png',
    action: `${process.env.REACT_APP_API_URL}/kyss/upload-logo`,
    onChange(info: any) {
      
      const { status } = info.file;
      
      if (status !== 'uploading') {
    
      }
      if (status === 'done') {
        getLogoUrl()
        OpenNotification(`Logo Uploaded Successfully`, 'success');

      } 
      if (status === 'error') {
        console.log(info.file)
        OpenNotification('Logo Upload Failed.', 'error');
      }
    },
    onDrop(e: any) {
      console.log(e);
      console.log('Dropped files', e.dataTransfer.files);
    },
    beforeUpload: (file:any,filesArray:any) => {
      setLogoFileName(file.name)
      if (file.size > 512000) {
        OpenNotification("Maximum size to upload is upto 500 Kb", 'error')
        return false
      }
      const isImage = ['image/png','image/jpeg'].includes(file.type);

      if(!isImage){
        OpenNotification('Uploaded file is not Image', 'error');
        return false
      }
      return true
    },
    
  };



  return (
    <main>
      {/* whatsappp Modal */}
      <Modal
        className="whatsappModal"
        title=""
        centered
        visible={isWhatsappModalVisible}
        onOk={handleWhatsappOk}
        onCancel={handleWhatsappCancel}
        footer={[]}
      >
        <section className="whatsappSection">
          <h3 className="whatsappSection__title">
            Are you sure you don't want updates on WhatsApp ?
            {' '}
            <br />
            You might miss important updates.
          </h3>
          <div className="whatsappSection__getupdate">
            <Button
              className="whatsappSection__getupdate__btn"
              style={{
                backgroundColor: THEME.PRIMARY_COLOR,
              }}
              onClick={() => handleWhatsAppConsent(true)}
            >
              Get Update on WhatsApp
            </Button>
          </div>
          <div className="whatsappSection__nosection">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <p
              className="whatsappSection__nosection__text"
              onClick={() => handleWhatsAppConsent(false)}
            >
              No Thanks
            </p>
          </div>
        </section>
      </Modal>

      <Modal
        className="whatsappModal"
        title=""
        centered
        visible={isVerifyMobileModalVisible}
        onOk={closeVerifyMobileModal}
        onCancel={closeVerifyMobileModal}
        footer={[]}
      >
        <section className="whatsappSection">
          <h3 className="whatsappSection__title">
            To verify existing mobile number, enter OTP sent to your mobile number
            {' '}
            <br />
            ending in
            {' '}
            { (phoneNumber.toString() || '').replace(/^[^@]+(.{3})/, '*******X$1') }
          </h3>
          <Row justify="center">
            <div className="flex-y otp-wrapper" onKeyDown={(e) => e?.key === 'Enter' && submitOtp()} role='presentation'>
              <OtpInput
                value={otpp}
                onChange={(val: string) => setotpp(val)}
                numInputs={4}
                className="otp-input mt--10"
                hasErrored={otpError}
                isInputNum
                errorStyle="input--error"
              />
            </div>
          </Row>
          <Row
            justify="center"
            align="middle"
          >
            <Col>
              <Button
                type="primary"
                disabled={!(otpp && otpp.length === 4)}
                onClick={submitOtp}
                style={{
                  backgroundColor: THEME.PRIMARY_COLOR,
                  float: 'right',
                  marginTop: '10px',
                  borderRadius:'5px',
                  fontWeight:'bold'
                }}
              >
                Validate
              </Button>
            </Col>
          </Row>
          <Row justify="center" align="middle" style={{ padding: '10px' }}>
            <Col>
              <Button
                type="dashed"
                disabled={isResendDisabled}
                shape="round"
                size="small"
                onClick={resendOtpAndDisableBtn}
              >
                Resend OTP
              </Button>
            </Col>
          </Row>
        </section>
      </Modal>
      {/* endModal */}
      {/* Logo Modal */}
      <Modal
        className="logo-view-modal"
        title=""
        centered
        visible={isLogoViewModalOpen}
        onOk={handleLogoModalClose}
        onCancel={handleLogoModalClose}
        footer={[]}
      >
        <section className='logo-view-modal--modal'>
          <img src={logoUrl} width="100%" height="100%" alt="logo"/>
        </section>
      </Modal>
      {/* Logo Modal end */}
      <Spin spinning={loading}>
        <div className="app__dashboard">
          <Row justify="center" align="middle" style={{ paddingBottom: '30px' }}>
            <Col flex="auto">
              <Title level={3}>{value?.trade_name}</Title>
            </Col>
          </Row>
          <Tabs defaultActiveKey="1" className='custom_tabs'>
            <TabPane tab="Company" key="1">
              <div style={{ fontSize: '16px',paddingBottom: '5px' }} className="profile-section-title">General details</div>
              <Form
                form={form}
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={onAddDetailsFinish}
                onFinishFailed={onAddDetailsFailed}
                autoComplete="off"
                className="form"
              >
                <Card className="detailscard">
                  <div className="detailscard__threecolgrid">
                    <Form.Item
                      label="Date of registration on KYSS"
                      name="registration_date"
                    >
                      <Input
                        disabled
                        name="registration_date"
                        value={value?.registration_date}
                        onChange={onDetailsChange}
                        placeholder={value ? value.registration_date : '02/02/2021'}
                        className='input-class'
                      />
                    </Form.Item>
                    <Form.Item label="GSTIN" name="gstin">
                      <Input
                        disabled
                        name="gstin"
                        value={value?.gstin}
                        onChange={onDetailsChange}
                        placeholder={value ? value.gstin : 'Enter your GSTIN'}
                        className='input-class'
                      />
                    </Form.Item>
                    <Form.Item label="State" name="state">
                      <Input
                        disabled
                        name="state"
                        value={value?.state}
                        onChange={onDetailsChange}
                        placeholder={value ? value.state : 'Enter State'}
                        className='input-class'
                      />
                    </Form.Item>
                    <Form.Item label="Business Name" name="trade_name">
                      <Input
                        disabled
                        name="trade_name"
                        value={value?.trade_name}
                        onChange={onDetailsChange}
                        placeholder={value ? value.trade_name : 'Enter Trade Name'}
                        className='input-class'
                      />
                    </Form.Item>
                    <Form.Item label="Company PAN" name="pan">
                      <Input
                        disabled
                        name="pan"
                        value={value?.pan}
                        onChange={onDetailsChange}
                        placeholder={value ? value.pan : 'Enter PAN'}
                        className='input-class'
                      />
                    </Form.Item>
                    <Form.Item label="Address" name="address">
                      <Input.TextArea
                        disabled
                        name="address"
                        value={value?.address}
                        onChange={onDetailsChange}
                        placeholder={value ? value.address : 'Enter Address'}
                        className='input-class'
                      />
                    </Form.Item>
                  </div>
                  <div className="detailscard__twocolgrid">
                    <Form.Item label="Type of Business" name="business_type">
                      <Radio.Group
                        style={{ paddingTop: '10px' }}
                        value={value?.business_type}
                        name="business_type"
                        onChange={onDetailsChange}
                      >
                        <Space direction="vertical" size="large">
                          <Radio value="Manufacturer/Trader/Supplier of Goods">
                            <span className="radiotext">
                              Manufacturer/Trader/Supplier of Goods
                            </span>
                          </Radio>
                          <Radio value="Service Provider">
                            <span className="radiotext">Service Provider</span>
                          </Radio>
                          <Radio value="Both">
                            <span className="radiotext">Both</span>
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                    {/*<Form.Item label="Upload company logo" name="companyLogo">*/}
                    {/*  <div className="dragger">*/}
                    {/*    <Dragger beforeUpload={beforeUpload}>*/}
                    {/*      <p className="">Drag & Drop</p>*/}
                    {/*      /!* <Upload beforeUpload={beforeUpload}> *!/*/}
                    {/*      <p className="dragger__text">Browse</p>*/}
                    {/*      /!* </Upload> *!/*/}
                    {/*    </Dragger>*/}
                    {/*  </div>*/}
                    {/*</Form.Item>*/}
                  </div>
                  <div className="detailscard__threecolgrid">
                    <div>
                    <Form.Item
                      style={{marginBottom: '4px'}}
                      label="Company Email"
                      name="email"
                      rules={[
                        // {
                        //   type: 'email',
                        //   message: 'Please enter valid email',
                        // },
                        {
                          // pattern: new RegExp(/^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/),
                          pattern: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
                          message: 'Please enter valid email',
                        },
                      ]}
                    >
                      <Input
                        name="email"
                        value={value?.email}
                        onChange={onDetailsChange}
                        placeholder={value ? value.email : 'Enter Email'}
                        className='input-class'
                      />
                    </Form.Item>
                    {isNoteVisible && <p className="email-info-text">Note: Entering invalid email might cause trouble during login.</p>}
                    </div>
                    <Form.Item
                      label="Mobile Number"
                      name="phone"
                      rules={[
                        {
                          pattern: new RegExp(/^[6-9]{1}[0-9]{9}$/),
                          message: 'Please enter valid mobile number',
                        },
                      ]}
                    >
                      {/*<Input.Group compact>*/}
                        <Input
                          name="phone"
                          placeholder={value ? value.phone : 'Enter Phone'}
                          value={value?.phone}
                          onChange={onDetailsChange}
                          suffix={<RiEditLine />}
                          className='input-class'
                        />

                        {/* <Button icon={<RiEditLine />} /> */}
                      {/*</Input.Group>*/}
                    </Form.Item>
                    <Form.Item
                      label="Type of Registration"
                      name="registration_type"
                    >
                      <Input
                        disabled
                        name="registration_type"
                        value={value?.registration_type}
                        onChange={onDetailsChange}
                        placeholder={value ? value.registration_type : ''}
                        className='input-class'
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item name="whatsapp_opt">
                      <Checkbox onChange={onDetailsChange} checked={value.whatsapp_opt} name="whatsapp_opt">
                        Get Updates on Whatsapp
                      </Checkbox>
                    </Form.Item>
                  </div>
                </Card>

                <div style={{ fontSize: '16px', paddingTop: '20px', paddingBottom: '5px' }} className="profile-section-title">
                  Bank details
                </div>
                {value.bankDetails.map((ele: any, index: number) => (
                  <>
                    <Card className="bank-details-card" key={index+1}>
                      {index > 0 && (
                      <div className="bank-details-card__closeicon">
                        <CloseOutlined
                          style={{
                            fontSize: '1.2rem',
                            cursor: 'pointer',
                            color: '#4532bf',
                          }}
                          onClick={() => removeBankRow(value.bankDetails[index].id ? value.bankDetails[index].id : 0,index)}
                        />
                      </div>
                      )}

                      {index==0 && (
                      <div className="bank-details-card__closeicon">
                        <CloseOutlined
                          style={{
                            fontSize: '1.2rem',
                            cursor: 'pointer',
                            color: '#4532bf',
                          }}
                          onClick={()=> clearBank(value.bankDetails[index].id ? value.bankDetails[index].id : 0,index)}
                        />
                      </div>
                      )}

                      <div className="bank-details-card__grid">
                        <Form.Item label="Bank name" name={[index, 'bank_name']}
                        rules={[
                          {
                            required: true,
                            message: 'Bank name is a mandatory field',
                          },
                        ]}>

                          <Input
                            name="bank_name"
                            value={value.bankDetails[index].bankName}
                            onChange={(e) => onBankDetailsChange(e, index, value.bankDetails)}
                            placeholder="Enter bank name"
                            className='input-class'
                          />
                        </Form.Item>
                        <Form.Item label="Branch" name={[index, 'branch']}
                        rules={[
                          {
                            required: true,
                            message: 'Branch is a mandatory field',
                          },
                        ]}
                        >
                          <Input
                            name="branch"
                            value={value.bankDetails[index].branch}
                            onChange={(e) => onBankDetailsChange(e, index, value.bankDetails)}
                            placeholder="Enter branch"
                            className='input-class'
                          />
                        </Form.Item>
                        <Form.Item label="IFSC" name={[index, 'ifsc']}
                        rules={[
                          {
                            required: true,
                            message: 'IFSC is a mandatory field',
                          },
                        ]}>
                          <Input
                            name="ifsc"
                            value={value.bankDetails[index].ifsc}
                            onChange={(e) => onBankDetailsChange(e, index, value.bankDetails)}
                            placeholder="Enter IFSC"
                            className='input-class'
                          />
                        </Form.Item>
                        <Form.Item label="Account no" name={[index, 'account_no']}
                        rules={[
                          {
                            required: true,
                            message: 'Account no. is a mandatory field',
                          },
                        ]}>
                          <Input
                            name="account_no"
                            value={value.bankDetails[index].account_no}
                            onChange={(e) => onBankDetailsChange(e, index, value.bankDetails)}
                            placeholder="Enter account number"
                            className='input-class'
                          />
                        </Form.Item>
                        <Form.Item label="Branch swift" name={[index, 'branch_swift']}
                        rules={[
                          {
                            required: true,
                            message: 'Branch swift is a mandatory field',
                          },
                        ]}>
                          <Input
                            name="branch_swift"
                            value={value.bankDetails[index].branch_swift}
                            onChange={(e) => onBankDetailsChange(e, index, value.bankDetails)}
                            placeholder="Enter branch swift"
                            className='input-class'
                          />
                        </Form.Item>
                      </div>
                    </Card>
                  </>
                ))}
                <Row style={{ paddingTop: '10px' }}>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <p className="addanotherbanktext" onClick={addAnotherBank}>
                      {' '}
                      + Add Another Bank
                    </p>
                  </Col>
                </Row>
                {/*<div style={{ fontSize: '16px', paddingTop: '20px' }}>*/}
                {/*  Certificates*/}
                {/*</div>*/}
                {/*<Card className="certificate-section">*/}
                {/*  <div className="certificate-section__grid">*/}
                {/*    <div className="certificate-section__grid__ele">*/}
                {/*      <a href="#">Upload GST Certificate</a>*/}
                {/*    </div>*/}
                {/*    <div className="certificate-section__grid__ele">*/}
                {/*      <a href="#">Upload Company PAN</a>*/}
                {/*    </div>*/}
                {/*    <div className="certificate-section__grid__ele">*/}
                {/*      <a href="#">Upload POA</a>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</Card>*/}

                <Button
                  htmlType="submit"
                  type="primary"
                  style={{
                    backgroundColor: THEME.PRIMARY_COLOR,
                    float: 'right',
                    marginTop: '10px',
                    borderRadius:'5px',
                    fontWeight:'bold',
                    marginBottom: '4rem'
                  }}
                >
                  Save Changes
                </Button>
              </Form>
            </TabPane>
            <TabPane tab="Invoice Settings" key="2">
              <div className="detailscard__threecolgrid">
                <Form.Item
                  label="E-Invoicing Username"
                  name="gst_username"
                >
                  <Input
                    name="gst_username"
                    value={value?.gst_username}
                    onChange={onDetailsChange}
                    placeholder={value.gst_username ? value.gst_username : 'Enter username'}
                  />
                </Form.Item>
                <Form.Item
                  label="E-Invoicing Password"
                  name="gst_password"
                >
                  <Input.Password
                    name="gst_password"
                    value={value?.gst_password}
                    onChange={onDetailsChange}
                    placeholder={value.gst_password ? value.gst_password : 'Enter password'}
                  />
                </Form.Item>
              </div>
              <div className="detailscard__threecolgrid">
                <Form.Item
                  label="E-Way Username"
                  name="eway_username"
                >
                  <Input
                    name="eway_username"
                    value={value?.eway_username}
                    onChange={onDetailsChange}
                    placeholder={value.eway_username ? value.eway_username : 'Enter username'}
                  />
                </Form.Item>
                <Form.Item
                  label="E-Way Password"
                  name="eway_password"
                >
                  <Input.Password
                    name="eway_password"
                    value={value?.eway_password}
                    onChange={onDetailsChange}
                    placeholder={value.eway_password ? value.eway_password : 'Enter password'}
                  />
                </Form.Item>
              </div>
              <div className="logo-section">
           
                  <h3 className="invoice-settings-section__title">
                    Company Logo
                  </h3>
                  {logoUrl ?<div className="logo-section--view">
                  <Button icon={<EyeOutlined />} onClick={() => setIsLogoViewModalOpen(true)}> View</Button>
                  <div style={{textAlign:"center"}}>
                  <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Replace</Button>
                  </Upload>
                  <p className='ant-upload-list-item' style={{display:"block"}}>{logoFileName}</p>
                  </div>
                  </div>:<div className="logo-section--upload">
                  <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                  <p className='ant-upload-list-item' style={{display:"block"}}>{logoFileName}</p>
                  </div >}
                  {/* <Card className="invoice-settings-card">
                    <section className="fileupload-section">
                    <div>
                      <div className="fileupload-section__dragger">
                      <Dragger {...props}>
                      <p className="fileupload-section__dragger__text">
                       Drag & drop <br></br>file
                      </p>
                      </Dragger>
                    </div>
                    <div className="fileupload-section__browse">
                      <Upload {...props}>
                      <Button className="fileupload-section__browse__btn">
                        Browse
                      </Button>
                      </Upload>
                    </div>
                    </div>
                    </section>
                  </Card> */}
                </div>
              {[1, 2].map((ele, idx) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <div className="invoice-settings-section">
                    <h3 className="invoice-settings-section__title">
                      {ele === 1 ? 'B2B Invoice' : 'B2C Invoice'}
                    </h3>
                    <Card className="invoice-settings-card">
                      <div className="invoice-settings-card__header">
                        <p className="invoice-settings-card__header__text">
                          Select Theme
                        </p>
                        <div className="invoice-settings-card__header__colorpickerblock">
                          <ImportedColorPicker onColorChange={onColorChange} index={idx} color={idx === 0 ? invSettings?.b2b_theme : invSettings?.b2c_theme} />
                          <p className="invoice-settings-card__header__subtext">
                            Color
                          </p>
                        </div>
                      </div>
                      <div className="invoice-settings-card__twocolgrid">
                        {/*  eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <div className={`invoice-settings-card__preview ${activePreview[idx] === true &&'active-border'}`} onClick={() => handlePreviewClick(idx, true)}>
                          <img
                            src={withLogoInvoice}
                            alt="QR Code"
                            style={{ width:'210px', height:'215px' }}
                          />
                        </div>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <div className={`invoice-settings-card__preview ${activePreview[idx] === false &&'active-border'}`} onClick={() => handlePreviewClick(idx, false)}>
                          <img
                            src={withoutLogoInvoice}
                            alt="QR Code"
                            style={{ width:'210px', height:'215px' }}
                          />
                        </div>
                      </div>
                      <div className="invoice-settings-card__footer">
                        <div className="invoice-settings-card__footer__firstele">
                          <p className="invoice-settings-card__footer__firstele--text" style={{marginBottom: '20px'}}>
                            Invoice prefix
                          </p>
                          <Form
                            form={prefixForm}
                          >
                            <Form.Item
                              name={idx === 0 ? 'b2b_prefix' : 'b2c_prefix'}
                              rules={[
                                {
                                  pattern: new RegExp(/^[ A-Za-z0-9_-]{1,4}$/),
                                  message: 'Enter a valid prefix',
                                },
                              ]}
                            >
                              <Input
                                name={idx === 0 ? 'b2b_prefix' : 'b2c_prefix'}
                                className="invoice-settings-card__footer__firstele--input"
                                placeholder=""
                                onChange={onInvSettingsChange}
                                value={idx === 0 ? invSettings?.b2b_prefix : invSettings?.b2c_prefix}
                              />
                            </Form.Item>
                          </Form>
                          <p className="invoice-settings-card__footer__firstele--subtext" style={{marginBottom: '20px'}}>
                            {' '}
                            This prefix will be added to all your invoice numbers.
                          </p>
                        </div>
                        <div className="invoice-settings-card__footer__secondele">
                          {/*<p className="invoice-settings-card__footer__secondele--cta">*/}
                          {/*  Preview*/}
                          {/*</p>*/}
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })}
              <Button
                onClick={onAddDetailsFinish}
                type="primary"
                style={{
                  backgroundColor: THEME.PRIMARY_COLOR,
                  float: 'right',
                  marginTop: '10px',
                  borderRadius:'5px',
                  fontWeight:'bold'
                }}
              >
                Confirm
              </Button>
            </TabPane>
            {/*<TabPane tab="Subscription" key="3">*/}
            {/*  Content of Subscription Tab*/}
            {/*</TabPane>*/}
          </Tabs>
        </div>
      </Spin>
    </main>
  );
}
