import React, { useRef } from 'react';
import {
  Card,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Tabs,
  Table,
  DatePicker,
  Modal,
  Upload,
  Dropdown,
  Menu,
  Select,
} from 'antd';
import { THEME } from '../constants/theme';
import {
  PlusOutlined,
  FilterOutlined,
  ApartmentOutlined,
  EditFilled,
  DeleteFilled,
  CheckCircleTwoTone,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {useHistory, useLocation} from 'react-router';
import { ROUTES } from '../constants/routePaths';
import { FiEdit3 } from 'react-icons/fi';
import {globalApiService} from "../services/apiService";
import {AxiosError} from "axios";
import {useSelector} from "react-redux";
import {IState} from "../models";
import {useReactToPrint} from "react-to-print";
import OpenNotification from '../utils/notification';

const EWayBillPreview = () => {

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //Initilization
  const authState = useSelector((state: IState) => state.authReducer);
  const history = useHistory();
  const location:any = useLocation();
  const {formData} = location?.state;
  const [qrCode, setQrCode] = React.useState<any>('')
  const [values,setvalues] = React.useState<any>({});
  const [shareDetails,setShareDetails] = React.useState<any>({
    email_check: false,
    email_copy: false,
    whatsapp_check: false,
    email_to:'',
    whatsapp_to:''
  });
  const [eWayGenerationState, setEWayGenerationState] = React.useState(false);
  const [form] = Form.useForm();
  const [isIrnAvailableForEway, setIsIrnAvailbleForEway] = React.useState(false);
  //Destructuring
  const { Title, Paragraph, Text } = Typography;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getDateOnly = (date_timestamp_string:string) => {
    return date_timestamp_string.split(" ").shift()
  }

  const generateEwb = (id:number,gstin:any, share_details:any, data:any) => {
    if(!isIrnAvailableForEway){
      globalApiService
      .generateEwayBillWithoutIrn( { 'ewb_id': id, 'gstin': gstin,'share_details':share_details,'form_data':data })
      .then((res) => {
        if(!res.data.success){
          if(typeof res.data.message === "object" && 'message' in res.data.message){
          OpenNotification(res.data.message.message,'error')
          return;
          }
          OpenNotification(res.data.message,'error')
        }
        if(res.data.success){
          setvalues({...values, 'valid_from':getDateOnly(res.data.data.ewayBillDate), 'valid_to':getDateOnly(res.data.data.validUpto), 'eway_number': res.data.data.ewayBillNo,'eway_bill_date': getDateOnly(res.data.data.ewayBillDate)})
          setEWayGenerationState(true);
        }

      })
      .catch((err: any) => {
 
        const errorBody = err?.response?.data;

      });
      return;
    }

    if(isIrnAvailableForEway){
    globalApiService
    .generateEwb(id,gstin, share_details, data)
    .then((res) => {
      if (res.data.success) {
        let eway_date = moment(new Date(res.data.data.eway_date)).format('DD/MM/YYYY')
        let ewb_from_date = moment(new Date(res.data.data.data.EwbDt)).format('DD/MM/YYYY')
        let ewb_end_date = moment(new Date(res.data.data.data.EwbValidTill)).format('DD/MM/YYYY')
        setvalues({...values, 'valid_from':ewb_from_date, 'valid_to':ewb_end_date, 'eway_number': res.data.data.eway_no, 'eway_bill_date': eway_date})
        setEWayGenerationState(true);
    }
    })
    .catch((err: AxiosError) => {
      const errorBody = err?.response?.data;
    });
    return;
  }
  };

  const generateQrCode = (docNumber: string) => {
    globalApiService
      .generateQrCode(docNumber)
      .then((res) => {
        if (res.data.success) {
          setQrCode(res.data.data.qr_code);
        }
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
      });
  };


  React.useEffect(() => {
    setvalues(formData);
    generateQrCode(formData.doc_number);
    if('ewb_id' in formData){
      globalApiService
      .getIrnForEway(formData.ewb_id)
      .then((res) => {
        if(res.data.data.irn){
          setIsIrnAvailbleForEway(true)
        }
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
      });
    }
  }, [formData])

  const handleGenerateClick = () => { 
      generateEwb(formData.ewb_id, authState.gstin, shareDetails, values)
  }
  React.useEffect(()=> {
    if(!shareDetails.email_check){
      form.resetFields(['email_to'])
    }
    if(!shareDetails.whatsapp_check){
      form.resetFields(['whatsapp_to'])
    }

  },[shareDetails.email_check,shareDetails.whatsapp_check])

  return (
    <section className="ewaybill-preview">
      <div className="ewaybill-preview-threecolgrid">
        <div className="ewaybill-preview-threecolgrid__firstele">
          <ArrowLeftOutlined
            className="ewaybill-preview-threecolgrid__firstele__icon"
            onClick={() => history.push(ROUTES.E_INVOICE)}
          />
        </div>
        <div className="ewaybill-preview-threecolgrid__secondele" ref={componentRef}>
          <Card className="ewaybill-preview-card">
            <div className="ewaybill-preview-card__header">
              <h3 className="ewaybill-preview-card__header__text">
                E way bill
              </h3>
              <div className="ewaybill-preview-card__header__imgcontainer">
                {qrCode != '' && (
                  <img
                  src={`data:image/png;base64,${qrCode}`}
                  alt="QR Code"
                  className="ewaybill-preview-card__header__img"
                />
                )}
              </div>
            </div>
            <div className="ewaybill-preview-card__twocolgrid">
              <p className="ewaybill-preview-card__twocolgrid__label">
                E way bill no
              </p>
              <p className="ewaybill-preview-card__twocolgrid__text">
                {values?.eway_number || ''}
              </p>
              <p className="ewaybill-preview-card__twocolgrid__label">
                E way bill date
              </p>
              <p className="ewaybill-preview-card__twocolgrid__text">
                {values?.eway_bill_date || ''}
              </p>
              <p className="ewaybill-preview-card__twocolgrid__label">
                Generated by
              </p>
              <p className="ewaybill-preview-card__twocolgrid__text">
                {values?.from_gstin || ''}
                {' '}
                {values?.from_name || ''}
              </p>
              <p className="ewaybill-preview-card__twocolgrid__label">
                Valid from
              </p>
              <p className="ewaybill-preview-card__twocolgrid__text">
                {values?.valid_from || ''}
              </p>
              <p className="ewaybill-preview-card__twocolgrid__label">
                Valid to
              </p>
              <p className="ewaybill-preview-card__twocolgrid__text">
                {values?.valid_to || ''}
              </p>
            </div>
            <h3 className="ewaybill-preview-card__subheading">Part A</h3>
            <div className="ewaybill-preview-card__twocolgrid">
              <p className="ewaybill-preview-card__twocolgrid__label">
                GSTIN of recipient
              </p>
              <p className="ewaybill-preview-card__twocolgrid__text">
                {values?.to_gstin || ''}
              </p>
              <p className="ewaybill-preview-card__twocolgrid__label">
                Place of delivery
              </p>
              <p className="ewaybill-preview-card__twocolgrid__text">
                {values?.to_place || ''}
              </p>
              <p className="ewaybill-preview-card__twocolgrid__label">
                Invoice/ Challan no.
              </p>
              <p className="ewaybill-preview-card__twocolgrid__text">{values?.doc_number || ''} </p>
              <p className="ewaybill-preview-card__twocolgrid__label">
                Invoice/ Challan date
              </p>
              <p className="ewaybill-preview-card__twocolgrid__text">
                {values?.doc_date || ''}
              </p>
              <p className="ewaybill-preview-card__twocolgrid__label">
                Value of goods
              </p>
              <p className="ewaybill-preview-card__twocolgrid__text">
                Rs. {values?.total_value || ''}
              </p>
              <p className="ewaybill-preview-card__twocolgrid__label">
                HSN code
              </p>
              <p className="ewaybill-preview-card__twocolgrid__text">{values?.hsn_code || ''}</p>
              <p className="ewaybill-preview-card__twocolgrid__label">
                Reason for transportation
              </p>
              <p className="ewaybill-preview-card__twocolgrid__text">
                {values?.transaction_type || ''}
                - {values?.sub_type || ''}
              </p>
              <p className="ewaybill-preview-card__twocolgrid__label">
                Transport no. and name
              </p>
              <p className="ewaybill-preview-card__twocolgrid__text">
                { values?.transport_id || '' } { values?.transporter_name || '' }</p>
              <p className="ewaybill-preview-card__twocolgrid__label">
                Transport doc no. and date
              </p>
              <p className="ewaybill-preview-card__twocolgrid__text">
                {values?.transporter_doc_number || ''} {values?.transport_date || ''}
              </p>
            </div>
            <h3 className="ewaybill-preview-card__subheading">Part B</h3>
            <div className="ewaybill-preview-card__twocolgrid">
              <p className="ewaybill-preview-card__twocolgrid__label">Mode</p>
                <p className="ewaybill-preview-card__twocolgrid__text">
                  {values?.mode_of_transportation || ''}
                </p>
              <p className="ewaybill-preview-card__twocolgrid__label">
                Vehicle number
              </p>
                <p className="ewaybill-preview-card__twocolgrid__text">
                  {values?.vehicle_number || ''}
                </p>
            </div>
            <div className="ewaybill-preview-card__fivecolgrid">
              {/*<p className="ewaybill-preview-card__fivecolgrid__label">From</p>*/}
              {/*<p className="ewaybill-preview-card__fivecolgrid__label">*/}
              {/*  Entered date*/}
              {/*</p>*/}
              {/*<p className="ewaybill-preview-card__fivecolgrid__label">*/}
              {/*  Entered by*/}
              {/*</p>*/}
                <p className="ewaybill-preview-card__fivecolgrid__text">
                </p>
                <p className="ewaybill-preview-card__fivecolgrid__text">
                </p>
                <p className="ewaybill-preview-card__fivecolgrid__text">
                </p>
            </div>
          </Card>
        </div>
        <div className="ewaybill-preview-threecolgrid__thirdele">
          {!eWayGenerationState && (
            <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={() => handleGenerateClick()}
            onFinishFailed={() => {}}
            autoComplete="on"
            className="einvoice-form"
            form={form}
          >
            <div className="ewaybill-preview-generate">
              <div className="ewaybill-preview-generate__ele">
                <Checkbox
                  onChange={(e) => setShareDetails({...shareDetails, 'email_check': e.target.checked})}
                  className="ewaybill-preview-generate__ele__checkbox ewaybill-preview-generate__ele__checkbox--margin-bottom"
                />

                <Form.Item label="" name="email_to" rules={[{ required:shareDetails.email_check, message: 'email is a manadatory field'},{pattern: new RegExp(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/),message: 'Enter a valid email'},]}>
                <Input
                  disabled={!shareDetails.email_check}
                  name="email_to"
                  value={shareDetails?.email_to || ''}
                  onChange={(e) => setShareDetails({...shareDetails, 'email_to': e.target.value})}
                  placeholder={'Email to..'}
                  className="ewaybill-preview-generate__ele__input"
                />
                </Form.Item>
                {/*<FiEdit3 className="ewaybill-preview-generate__ele__icon" />*/}
              </div>
              <div className="ewaybill-preview-generate__ele ewaybill-preview-generate__ele--margin-bottom">
                <Checkbox
                  style={{marginBottom:'1.5rem'}}
                  onChange={(e) => setShareDetails({...shareDetails, 'email_copy': e.target.checked})}
                  className="ewaybill-preview-generate__ele__checkbox"
                />
                <Input
                  readOnly
                  name="documentNumber"
                  // value={eWayBillState.documentNumber}
                  // onChange={onEWayBillChange}
                  //value="Send me a copy"
                  value={authState.email}
                  style={{marginBottom:'1.5rem'}}
                  className="ewaybill-preview-generate__ele__input"
                />
              </div>
              <div className="ewaybill-preview-generate__ele">
                <Checkbox
                  onChange={(e) => setShareDetails({...shareDetails, 'whatsapp_check': e.target.checked})}
                  className="ewaybill-preview-generate__ele__checkbox ewaybill-preview-generate__ele__checkbox--margin-bottom"
                />
                <Form.Item label="" name="whatsapp_to" rules={[{ required:shareDetails.whatsapp_check, message: 'whatsapp_to is a manadatory field'},{pattern: new RegExp(/^([+]\d{2})?\d{10}$/),message: 'Enter a valid phone no'}]}>
                <Input
                  disabled={!shareDetails.whatsapp_check}
                  name="whatsapp_to"
                  value={shareDetails?.whatsapp_to || ''}
                  onChange={(e) => setShareDetails({...shareDetails, 'whatsapp_to': e.target.value})}
                  placeholder={`WhatsApp to..`}
                  className="ewaybill-preview-generate__ele__input"
                />
                </Form.Item>
                {/*<FiEdit3 className="ewaybill-preview-generate__ele__icon" />*/}
              </div>
              <div className="ewaybill-preview-generate__btnele">
                <Button
                  className="ewaybill-preview-generate__btnele__btn ewaybill-preview-generate__btnele__btn--bg"
                  onClick={() =>  history.push(`/eInvoicing/ewayBill/${formData.ewb_id}`, {'data': {...formData}})}
                >
                  Edit
                </Button>
                <Button
                  className="ewaybill-preview-generate__btnele__btn"
                  //onClick={() => generateEwb(formData.ewb_id, authState.gstin, shareDetails, values)}
                  htmlType="submit"
                >
                  Generate
                </Button>
                <div className="ewaybill-preview-generate__btnele__textele">
                  <p className="ewaybill-preview-generate__btnele__textele--text"
                    onClick={() =>  history.push(ROUTES.E_INVOICE)}
                  >
                    Cancel
                  </p>
                </div>
              </div>
            </div>
            </Form>
          )}
          {eWayGenerationState && (
            <div className="ewaybill-preview-generate-success">
              <h3 className="ewaybill-preview-generate-success__text">
                E way successfully <br></br>generated!
              </h3>
              <div className="ewaybill-preview-generate-success__btnele">
                <Button
                  className="ewaybill-preview-generate-success__btn"
                  onClick={handlePrint}
                >
                  Print
                </Button>
              </div>
              <div className="ewaybill-preview-generate-success__cancelele">
                <p
                  className="ewaybill-preview-generate-success__cancelele__text"
                  onClick={() =>  history.push(ROUTES.E_INVOICE)}
                >
                  Cancel
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default EWayBillPreview;
