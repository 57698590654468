export enum UploadStatus{
  INIT = 'init',
  IN_PROGRESS = 'in_progress',
  SUCCESS = 'success',
  FAIL = 'fail',
  NONE = 'none'
}


export interface IAnalyticsZohoAccesToken{
  zohoToken: string;
}

export enum EAnalyticsSources{
  ZOHO = 'zoho'
}

export interface IAnalyticsState{
  tokenSet:{
    [EAnalyticsSources: string] : boolean
  },
  tokenReset:{
    [EAnalyticsSources: string] : boolean
  },
  loading: {
    [EAnalyticsSources: string] : boolean
  },
  data: {
    [EAnalyticsSources: string]: IZohoContactDetails
  }
  utmSources?:any
}

/* analytics types */
/* need to  be reorganized: AC */
export interface IZohoContactDetails{
  Last_Name: string;
  Email: string;
  First_Name: string;
  Mobile: string;
  gst_number: string
  Salutation: string;
  Account_Name: string;
  Source?: string;
  Added_Suppliers?: boolean;
  Started_Recon?: boolean;
  Description?: string;
}